import {
  DateFormater
} from "@/_helpers/funciones";
import {
  formatMilDecimal
} from '@/_validations/validacionEspeciales';

import {
  saveAs
} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import {
  mapState
} from 'vuex';

// Data
function data() {
  return {
    dataBuque: {
      CountryName: '',
      DatePlanning: '',
      VesselFlagRoute: '',
    },
  }
}

// Methods
function formatDate(date, server = false) {
  if (!date) return '';
  let arrDate = date.split(/[/ :-]/);

  if (!server) return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}`;
  else return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
}

function formatDateTime(date, server = false) {
  if (!date) return "";

  if (!server) {
    return DateFormater.formatDateTimeWithSlash(date);
  } else {
    let onlyDate = DateFormater.formatDateTimeWithSlashToOnlyDate(date);

    return `${this.formatDate(onlyDate, true)} ${DateFormater.formatDateTimeWithSlashToOnlyTime(date)}`;
  }
}

async function onBtnExportAll(items, excel = true, titulo = '', headerInfo = [], totals = false) {
  // this.loading = true;
  if (items.length !== 0) {
    let rowData = [],
      totalFull = 0,
      totalTare = 0,
      totalNet = 0,
      totalNetTon = 0;
    for (let i = 0; i < items.length; i++) {
      rowData.push({
        "#": items[i].Nro,
        BL: items[i].NroBl,
        Consignatario: items[i].ClientName,
        Patio: items[i].YardName,
        FechaOrden: this.formatDateTime(items[i].TransactionDate),
        CiChofer: items[i].DriverCi,
        Chofer: items[i].DriverName,
        PlacaVehiculo: items[i].LicensePlate,
        NroGuia: items[i].GuideNumber,
        NroControl: items[i].ControlNumber,
        "Bruto (KG)": formatMilDecimal(items[i].FullWeight),
        "Tare (KG)": formatMilDecimal(items[i].TareWeight),
        "Neto (KG)": formatMilDecimal(items[i].NetWeight),
        "Neto (TON)": formatMilDecimal(items[i].NetWeightTon),
        FechaEntrada: this.formatDateTime(items[i].EntryDate),
        FechaSalida: this.formatDateTime(items[i].OutDate),
      });

      if(totals) {
        totalFull += items[i].FullWeight;
        totalTare += items[i].TareWeight;
        totalNet += items[i].NetWeight;
        totalNetTon += items[i].NetWeightTon;
      }
    }
    if(totals) {
      rowData.push({
        "#": '',
        BL: '',
        Consignatario: '',
        Patio: '',
        FechaOrden: '',
        CiChofer: '',
        Chofer: '',
        PlacaVehiculo: '',
        NroGuia: '',
        NroControl: '',
        "Bruto (KG)": formatMilDecimal(totalFull),
        "Tare (KG)": formatMilDecimal(totalTare),
        "Neto (KG)": formatMilDecimal(totalNet),
        "Neto (TON)": formatMilDecimal(totalNetTon),
        FechaEntrada: '',
        FechaSalida: '',
      });
    }
    await this.getExcelArray(
      rowData,
      titulo,
      excel,
      headerInfo,
      totals,
    );
    // this.Loading = false;
  }
}

async function onBtnExportErrors(items, title = '', headerInfo = []) {
  if (items.length !== 0) {
    let rowData = [];
    for (let i = 0; i < items.length; i++) {
      if (!Array.isArray(items[i].JsonError)) {
        rowData.push({
          "#": items[i].Nro,
          BL: items[i].NroBl,
          Consignatario: items[i].ClientName,
          Patio: items[i].YardName,
          FechaOrden: this.formatDateTime(items[i].TransactionDate),
          CiChofer: items[i].DriverCi,
          Chofer: items[i].DriverName,
          PlacaVehiculo: items[i].LicensePlate,
          NroGuia: items[i].GuideNumber,
          NroControl: items[i].ControlNumber,
          "Bruto (KG)": formatMilDecimal(items[i].FullWeight),
          "Tare (KG)": formatMilDecimal(items[i].TareWeight),
          "Neto (KG)": formatMilDecimal(items[i].NetWeight),
          "Neto (TON)": formatMilDecimal(items[i].NetWeightTon),
          FechaEntrada: this.formatDateTime(items[i].EntryDate),
          FechaSalida: this.formatDateTime(items[i].OutDate),
          Errors: items[i].JsonError.ErrorJson.map((error) => {
            return `${this.$t("label.column")} ${error.ColumnName}: ${error.ColumnValue} ${this.$i18n.locale == 'es' ? error.ErrorMessage : error.ErrorMessageEn}`;
          }).join(","),
        });
      }
    }
    await this.getExcelArray(
      rowData,
      title,
      true,
      headerInfo
    );
  }
}

async function getExcelArray(rowData, titulo, excel, headerInfo = [], totals = false) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(titulo);
  const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

  let y = 0;
  let letra = 12;
  let columnsDefs = [];
  let keyColumns = [];
  let bandera = true;

  columnsDefs = Object.keys(rowData[0]);
  keyColumns = columnsDefs;
  y = columnsDefs.length;
  bandera = false;

  //if (isUndefined(un)) un = 'TON';
  if (excel) {
    if (y < 10) letra = 8;
    worksheet.mergeCells('A1:B2');
    const response1 = await this.$http.getFile('base64', {
      path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
    const imageId = workbook.addImage({
      base64: myBase64Image,
      extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:B2');
    worksheet.getCell("A1").border = {
      top: {
        style: 'thin'
      },
      bottom: {
        style: 'thin'
      },
      left: {
        style: 'thin'
      },
      right: {
        style: 'thin'
      },
    }
    const rowIndex1 = worksheet.getRow(1);
    rowIndex1.height = 100;

    worksheet.mergeCells("C1:" + numeroLetra(y) + "2")
    const customCellCompanyBranch = worksheet.getCell("C1");

    const row1 = worksheet.getRow(1);
    row1.height = 60;
    customCellCompanyBranch.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
    };
    customCellCompanyBranch.note = {
      margins: {
        insetmode: 'auto',
        inset: [10, 10, 5, 5]
      }
    }
    customCellCompanyBranch.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'f2f2f2'
      }
    }
    customCellCompanyBranch.border = {
      top: {
        style: 'thin'
      },
      left: {
        style: 'thin'
      },
      right: {
        style: 'thin'
      },
    }
    customCellCompanyBranch.value = this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' + this.branch.BranchName;
    customCellCompanyBranch.alignment = {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center'
    }

    //titulo
    worksheet.getRow(3).height = 30;
    worksheet.mergeCells("A3:" + numeroLetra(y) + "3");
    const customCell2 = worksheet.getCell("A3");
    customCell2.font = {
      name: "Calibri",
      family: 4,
      size: 12,
      underline: false,
      bold: true
    };
    customCell2.alignment = {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center'
    }
    customCell2.value = titulo;
    customCell2.border = {
      top: {
        style: 'thin'
      },
      bottom: {
        style: 'thin'
      },
      left: {
        style: 'thin'
      },
      right: {
        style: 'thin'
      },
    }

    /***************************** Dynamic header info ************************************/
    let columnsQty = columnsDefs.length;
    const hoy = new Date();

    let divisionQty = headerInfo.length + 2,
      leap = Math.floor(columnsQty / divisionQty),
      leapOffset = 1,
      currentStart = 1,
      currentEnd = 1 + leap,
      leapEnd = numeroLetra(columnsQty);

    if (Array.isArray(headerInfo) && headerInfo.length > 0) {
      for (let index = 0; index < headerInfo.length; index++) {
        worksheet.mergeCells(`${numeroLetra(currentStart)}4:${numeroLetra(currentEnd)}4`);
        let auxCell = worksheet.getCell(`${numeroLetra(currentStart)}4`);
        auxCell.height = 50;
        auxCell.font = {
          name: "Calibri",
          family: 4,
          size: 11,
          underline: false,
          bold: true
        };

        auxCell.alignment = {
          wrapText: true,
          vertical: 'middle',
          horizontal: 'left'
        }
        auxCell.value = `${headerInfo[index].label}: ${headerInfo[index].value}`;
        auxCell.border = {
          top: {
            style: 'thin'
          },
          bottom: {
            style: 'thin'
          },
          right: {
            style: 'thin'
          },
          left: {
            style: 'thin'
          },
        }

        currentStart = currentEnd + leapOffset;
        currentEnd = currentStart + leap;
      }

      // Print Date
      worksheet.mergeCells(`${numeroLetra(currentStart)}4:${numeroLetra(currentEnd)}4`);
      const dateCell = worksheet.getCell(`${numeroLetra(currentStart)}4`);
      dateCell.height = 50;
      dateCell.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
      };

      dateCell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
      }
      dateCell.value = this.$t('label.printed') + ": " + DateFormater.formatTimeZoneToDateTime(hoy);
      dateCell.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }

      // Login User
      currentStart = currentEnd + leapOffset;
      worksheet.mergeCells(`${numeroLetra(currentStart)}4:${leapEnd}4`);
      const userCell = worksheet.getCell(`${numeroLetra(currentStart)}4`);
      userCell.height = 50;
      userCell.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
      };

      userCell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
      }
      userCell.value = this.$t('label.user') + ": " + this.user.UserName + (this.user.UserLastName ? ' ' + this.user.UserLastName : '');
      userCell.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
    } else {
      // Print Date
      worksheet.mergeCells(`${numeroLetra(currentStart)}4:${numeroLetra(currentEnd)}4`);
      const dateCell = worksheet.getCell(`${numeroLetra(currentStart)}4`);
      dateCell.height = 50;
      dateCell.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
      };

      dateCell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
      }
      dateCell.value = this.$t('label.printed') + ": " + DateFormater.formatTimeZoneToDateTime(hoy);
      dateCell.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }

      // Login User
      currentStart = currentEnd + leapOffset;
      worksheet.mergeCells(`${numeroLetra(currentStart)}4:${leapEnd}4`);
      const userCell = worksheet.getCell(`${numeroLetra(currentStart)}4`);
      userCell.height = 50;
      userCell.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
      };

      userCell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
      }
      userCell.value = this.$t('label.user') + ": " + this.user.UserName + (this.user.UserLastName ? ' ' + this.user.UserLastName : '');
      userCell.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
    }
    
    //linea
    worksheet.mergeCells("A5:" + leapEnd +"5");
    const customCell8 = worksheet.getCell("A5");
    customCell8.height = 50;
    customCell8.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
  }

  var headerRow = worksheet.addRow();
  headerRow.font = {
    name: "Calibri",
    family: 4,
    size: 11,
    underline: false,
    bold: true
  };
  /* headerRow.border = {
       top: { style: 'thin' },
       bottom: { style: 'thin' },
   } */
  headerRow.height = 30;

  let i = 0;
  columnsDefs.map(function (data) {
    let valor = bandera ? data.headerName.length : data.length;
    let long = 10;
    if ((valor >= 5) && (valor <= 12)) {
      long = 20;
    } else if ((valor >= 11) && (valor <= 18)) {
      long = 25;
    } else if ((valor >= 19) && (valor <= 26)) {
      long = 30;
    } else if (valor >= 27) {
      long = 40;
    }

    //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

    worksheet.getColumn(i + 1).width = long;
    //if (excel) {
    let cell = headerRow.getCell(i + 1);
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'cccccc'
      }
    }
    cell.border = {
      top: {
        style: 'thin'
      },
      bottom: {
        style: 'thin'
      },
      left: {
        style: 'thin'
      },
      right: {
        style: 'thin'
      },
    }

    cell.value = bandera ? data.headerName : data;
    cell.alignment = {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center'
    }
    //}        
    i++;
  })

  rowData.map(async function (data, currentIndex) {
    if (data.Cont20FtDeck == "DECK") {
      //Grupo 0
      worksheet.mergeCells("A6:A7");
      worksheet.mergeCells("B6:B7");
      //Grupo 1
      worksheet.mergeCells("C7:F7");
      let cellA4 = worksheet.getCell("D7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.Cont20FtDeck;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 2
      worksheet.mergeCells("G7:J7");
      cellA4 = worksheet.getCell("H7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.Cont20FtHold;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 3
      worksheet.mergeCells("K7:N7");
      cellA4 = worksheet.getCell("L7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.Cont20FtTotal;

      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }

    } else if (data.Cont20Full == "20F") {
      //Grupo 0
      worksheet.mergeCells("A6:A7");
      worksheet.mergeCells("B6:B7");
      worksheet.mergeCells("C6:C7");
      //Grupo 1
      worksheet.mergeCells("D7:E7");
      let cellA4 = worksheet.getCell("D7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.Cont20Full;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 2
      worksheet.mergeCells("F7:G7");
      cellA4 = worksheet.getCell("F7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.Cont20Empty;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 3
      worksheet.mergeCells("H7:I7");
      cellA4 = worksheet.getCell("H7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.Cont40Full;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 4
      worksheet.mergeCells("J7:K7");
      cellA4 = worksheet.getCell("J7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.Cont40Empty;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 5
      worksheet.mergeCells("L7:M7");
      cellA4 = worksheet.getCell("L7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.Cont45Full;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 6
      worksheet.mergeCells("N7:O7");
      cellA4 = worksheet.getCell("N7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.Cont45Empty;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
    } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {
      //Grupo 1
      worksheet.mergeCells("N7:P7");
      let cellA4 = worksheet.getCell("N7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.CtaFunctionCode;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 2
      worksheet.mergeCells("Q7:R7");
      cellA4 = worksheet.getCell("R7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.ComAddressIdentifier;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 3
      worksheet.mergeCells("S7:V7");
      cellA4 = worksheet.getCell("S7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.HanDescriptionCode;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 4
      worksheet.mergeCells("W7:Y7");
      cellA4 = worksheet.getCell("W7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.TsrServicePriorityCode;
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
    } else if (data.section == "totalLoad") {
      //Grupo 0
      worksheet.mergeCells("A6:A7");
      worksheet.mergeCells("B6:B7");
      //Grupo 1
      worksheet.mergeCells("C7:E7");
      let cellA4 = worksheet.getCell("C7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        },
      }
      cellA4.value = "DECK";
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
      //Grupo 2
      worksheet.mergeCells("F7:H7");
      cellA4 = worksheet.getCell("F7");
      cellA4.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
      }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = "HOLD";
      cellA4.border = {
        top: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        },
      }
    } else if(totals && currentIndex == rowData.length - 1) {
      let dataRow = worksheet.addRow();
      let indice = 0;

      keyColumns.map(function (data2) {
        let cell = dataRow.getCell(indice + 1);
        if(data[data2]){
          cell.value = data[data2];
          cell.border = {
            top: {
              style: 'thin'
            },
            bottom: {
              style: 'thin'
            },
            left: {
              style: 'thin'
            },
            right: {
              style: 'thin'
            },
          };
          cell.alignment = {
            wrapText: true,
            vertical: 'middle',
            horizontal: 'center'
          }
        }
        indice++;
      })
    } else {
      let dataRow = worksheet.addRow();
      let indice = 0;

      keyColumns.map(function (data2) {
        let cell = dataRow.getCell(indice + 1);
        cell.value = data[data2];
        //if (cell.value==)
        cell.border = {
          top: {
            style: 'thin'
          },
          bottom: {
            style: 'thin'
          },
          left: {
            style: 'thin'
          },
          right: {
            style: 'thin'
          },
        };
        cell.alignment = {
          wrapText: true,
          vertical: 'middle',
          horizontal: 'center'
        }
        indice++;
      })
    }
  })

  let fileName = documento;
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/", "");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
  //return false;
  if (excel) {
    workbook.xlsx.writeBuffer()
      .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
        this.$notify({
          group: 'container',
          text: this.$t('label.reportSuccessfully'),
          type: "success"
        });
      }).catch(err => {
        console.log(err);
      })
  } else {
    workbook.csv.writeBuffer()
      .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
        this.$notify({
          group: 'container',
          text: this.$t('label.reportSuccessfully'),
          type: "success"
        });
      })
  }
}

function numeroLetra(valor) {
  const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

  return abc[valor - 1];
}

export default {
  data,
  methods: {
    formatDate,
    formatDateTime,
    onBtnExportAll,
    onBtnExportErrors,
    getExcelArray
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      branch: state => state.auth.branch,
    }),
  },
}